import React from 'react'
import { Breadcrumb, Page } from '@sh24/ui-components'
import buildBreadcrumbLinks from '../../utils/build-breadcrumb-links'
import modulesToReactComponents from '../../utils/modules-to-react-components'
import sectionModules from './supported-section-modules'
import SectionContext from '../../contexts/section-context'
import { usePageContext } from '../../contexts/page-context'

const thisSite = () => process.env.NEXT_PUBLIC_SITE

const Sections = ({ sections }) => sections
  .filter((section) => section.site?.includes(thisSite()))
  .map((section, index) => {
    const page = usePageContext()
    const Container = section.width === 'full-width' ? React.Fragment : Page.Content
    const breadcrumbLinks = buildBreadcrumbLinks(page)

    return (
      <SectionContext.Provider value={section} key={section.id}>
        <Page.Section
          backgroundColour={section.backgroundColour}
          decoration={section.decoration}
        >
          <Container>
            {index === 0 && <Breadcrumb breadcrumbLinks={breadcrumbLinks} width={section?.width || 'standard'} />}
            {modulesToReactComponents(
              { supportedModules: sectionModules, modules: section.modules },
            )}
          </Container>
        </Page.Section>
      </SectionContext.Provider>
    )
  })

export default Sections
