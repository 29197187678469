import {
  getSiteConfig,
  getTranslations,
  getHomePage,
} from '@sh24/contentful-service'
import { previewClient, contentDeliveryClient } from '../services/contentful'
import PageTemplate from '../templates/generic-page-template'
import PageTemplateV2 from '../templates/generic-page-template.v2'

const templates = {
  page: PageTemplate,
  page_v2: PageTemplateV2,
}

const Home = ({ page }) => {
  const Template = templates[page.contentType]
  return Template ? <Template page={page} /> : null
}

export async function getStaticProps({ preview }) {
  const client = preview ? previewClient : contentDeliveryClient
  const siteConfig = await getSiteConfig({ client })
  const translations = await getTranslations({ client })
  const page = await getHomePage({ client })

  return {
    props: {
      preview: !!preview,
      page,
      siteConfig,
      translations,
    },
    revalidate: parseInt(process.env.REVALIDATE_WINDOW, 10),
    notFound: !page,
  }
}

export default Home
