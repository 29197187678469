import Head from 'next/head'
import { PageNavigation } from '@sh24/ui-components'
import { useEffect } from 'react'
import {
  CURRENT_JOURNEY_KEY,
  getSession,
  clearSession,
} from '../order-journeys/_v2/state/actions'
import { trackEcommercePurchase } from '../order-journeys/_v2/tracking'
import SEO from '../components/SEO/seo'
import Sections from '../components/Sections/sections'
import PageContext from '../contexts/page-context'
import JSONSchema from '../components/JSONSchema/json-schema'
import buildPageNav from '../utils/build-page-nav'
import useTranslations from '../utils/use-translations'
import lookupTranslations from '../utils/lookup-translations'

const GenericPageTemplate = ({ page }) => {
  const translations = useTranslations()
  const pageNavTranslations = lookupTranslations({ translations, lookup: 'pageNavigation.' })
  const pageNav = buildPageNav(page, pageNavTranslations)

  useEffect(() => {
    if (page.pageType === 'completion') {
      const sessionKey = window.sessionStorage.getItem(CURRENT_JOURNEY_KEY)
      const journeyState = getSession({ sessionKey })
      if (journeyState?.context?.sessionKey === sessionKey) {
        trackEcommercePurchase(journeyState.context)
        clearSession({ sessionKey })
      }
    }
  }, [])

  return (
    <PageContext.Provider value={page}>
      {page.jsonSchema && <JSONSchema id={page.id} schema={page.jsonSchema} />}
      {page.seo && <SEO seo={page.seo} url={page.url} Head={Head} />}
      {page.sections && <Sections sections={page.sections} />}
      {pageNav && (
        <PageNavigation
          prevPageLink={pageNav.prevPageLink}
          nextPageLink={pageNav.nextPageLink}
        />
      )}
    </PageContext.Provider>
  )
}

export default GenericPageTemplate
