import {
  AnchorModule,
  ButtonCloudModule,
  CalloutModule,
  CardModule,
  ContentModule,
  GalleryModule,
  GridModule,
  HelpSnippetModule,
  SearchInputModule,
  SupportLinkModule,
  AvailabilityCheckerModule,
  AvailabilityCheckerResultsModule,
} from '../Modules'

export default {
  anchor: AnchorModule,
  buttonCloud: ButtonCloudModule,
  callout: CalloutModule,
  card: CardModule,
  content: ContentModule,
  gallery: GalleryModule,
  grid: GridModule,
  helpSnippet: HelpSnippetModule,
  searchInput: SearchInputModule,
  supportLink: SupportLinkModule,
  availabilityChecker: AvailabilityCheckerModule,
  availabilityCheckerResults: AvailabilityCheckerResultsModule,
}
